<template>
  <button
    class="btn btn-transparent btn-sm"
    :title="$t(`files.moreInformation`)"
    v-on:click="show"
    v-if="(page !== 'product' || main > 0) && id > 0"
  >
    <BaseIcon name="document" />
    <span class="counter-badge regular-10" v-if="modelValue?.count">{{
      modelValue.count
    }}</span>
  </button>
  <FilesModal :id="id" :row="row" ref="modal" />
</template>
<script>
import { defineAsyncComponent } from "vue";
import FilesModal from "@/components/modals/FilesModal";
const BaseIcon = defineAsyncComponent(() => import("../../icons/BaseIcon.vue"));

export default {
  name: "Files",
  components: { FilesModal, BaseIcon },
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Object,
    main: Number,
  },
  data() {
    return {
      edit: false,
      value: this.modelValue,
    };
  },
  computed: {
    page() {
      if (this.row.row_key.indexOf("characteristics_") > -1) {
        return "product";
      } else {
        return "crm";
      }
    },
  },
  methods: {
    show() {
      this.$refs.modal.showModal();
    },
  },
};
</script>
